<template>
    <div class="deal_with_box">
        <div class="table-title">
            <div>
                <div class="title_left_btn">
                    <span
                        @click="getTableData(0)"
                        :class="active == 0 ? 'active' : ''"
                        >全部</span
                    >
                    <span
                        @click="getTableData(3)"
                        :class="active == 3 ? 'active' : ''"
                        >待确认</span
                    >
                    <span
                        @click="getTableData(4)"
                        :class="active == 4 ? 'active' : ''"
                        >已确认</span
                    >
                    <span
                        @click="getTableData(5)"
                        :class="active == 5 ? 'active' : ''"
                        >已反馈</span
                    >
                </div>
            </div>
        </div>
        <Table
            ref="table"
            @totalNum="totalNum"
            @onDetail="onDetail"
            @onFeedBack="onFeedBack"
        ></Table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 明细 -->
        <el-drawer
            :visible.sync="drawerDetail"
            :direction="direction"
            :before-close="handleDrawer"
            size="600px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    工资条明细
                </div>
            </template>
            <Detail
                @close="handleDrawer"
                @feedback="onFeedBack"
                @extend="extend"
                ref="detail"
            />
        </el-drawer>
        <!-- 问题反馈 -->
        <el-dialog
            :visible.sync="dialogFeedback"
            width="560px"
            :before-close="handleClose"
            append-to-bodycloseTime
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    问题反馈
                </div>
            </template>
            <Feedback
                ref="feedback"
                @close="close"
                @closeTime="closeTime"
            ></Feedback>
            <div
                slot="footer"
                class="dialog_footer"
                v-if="tableRow.status == 5"
            >
                <p>
                    问题已反馈给管理员，是否已解决？
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            background-color: #2370eb;
                            padding: 10px 16px;
                        "
                        @click="wagesGet2"
                        size="mini"
                        >已解决，确认工资条</el-button
                    >
                </p>
            </div>
        </el-dialog>
        <!-- 延长确认 -->
        <el-dialog
            :visible.sync="dialogSubmit"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    延长确认
                </div>
            </template>
            <div class="dialog_info">
                <p>
                    <i
                        class="el-icon-warning-outline"
                        style="color: #ff8a00"
                    ></i>
                    本次可延长24小时，确定后立即生效。<br />
                    如对工资有异议请尽快反馈哦～ <br />
                    （仅可延长一次）
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button
                    @click="handleClose"
                    round
                    size="mini"
                    style="padding: 8px 34px"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="
                        margin-right: 16px;
                        background-color: #2370eb;
                        padding: 8px 34px;
                    "
                    round
                    @click="wagesGet"
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { wagesGet, wagesUpdate } from '@/api/wages/wages.js';
import Detail from '../detail/detail.vue';
import Table from '../table/table.vue';
import Feedback from '../feedback/feedback.vue';
export default {
    data() {
        return {
            active: 0,
            currentPage: 1,
            pagesize: 20,
            total: 0,
            direction: 'rtl',
            drawerDetail: false,
            dialogFeedback: false,
            dialogSubmit: false,
            isPage: false,
            tableRow: {},
            btnP: {},
        };
    },
    components: {
        Table,
        Detail,
        Feedback,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            this.getData();
        },
        getTableData(active) {
            this.currentPage = 1;
            this.active = active;
            this.getData();
        },
        getData() {
            this.$refs.table.getData(
                this.active,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        totalNum(row) {
            this.total = row;
        },
        onFeedBack(row) {
            this.tableRow = row;
            this.dialogFeedback = true;
            this.wagesGet();
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    status: 4,
                },
            };
            wagesUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.tableRow = {};
                    this.drawerDetail = false;
                    this.dialogFeedback = false;
                    this.getData();
                }
            });
        },
        extend(row) {
            this.tableRow = row;
            this.dialogSubmit = true;
        },
        onSubmitExtend() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    status: 3,
                },
            };
            wagesUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.tableRow = {};
                    this.drawerDetail = false;
                    this.dialogSubmit = false;
                    this.getData();
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        onDetail(row) {
            this.tableRow = row;
            this.drawerDetail = true;
            this.wagesGet();
        },
        wagesGet2() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            wagesGet(data).then((res) => {
                if (res.code == 200) {
                    this.tableRow = res.data;
                    if (res.data.status == 6) {
                        this.tableRow = {};
                        this.drawerDetail = false;
                        this.dialogFeedback = false;
                        this.getData();
                        return this.$message.error(
                            '当前工资条状态异常，无法完成操作'
                        );
                    }
                    if (this.dialogFeedback) {
                        this.onSubmit();
                    }
                }
            });
        },
        closeTime(data) {
            console.log(data);
            this.tableRow.status = 5;
            if (this.drawerDetail) {
                this.$refs.detail.getData(data);
            }
        },
        wagesGet() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            wagesGet(data).then((res) => {
                if (res.code == 200) {
                    this.tableRow = res.data;
                    if (res.data.status == 6) {
                        this.tableRow = {};
                        this.drawerDetail = false;
                        this.dialogSubmit = false;
                        this.dialogFeedback = false;
                        this.getData();
                        return this.$message.error(
                            '当前工资条状态异常，无法完成操作'
                        );
                    }
                    if (this.dialogFeedback) {
                        this.$refs.feedback.getData(res.data);
                    }
                    if (this.drawerDetail) {
                        this.$refs.detail.getData(res.data);
                    }
                    if (this.dialogSubmit) {
                        this.onSubmitExtend();
                    }
                }
            });
        },
        handleDrawer() {
            this.tableRow = {};
            this.drawerDetail = false;
            this.getData();
        },
        handleClose() {
            this.tableRow = {};
            this.dialogFeedback = false;
            this.dialogSubmit = false;
            this.getData();
        },
        close() {
            this.drawerDetail = false;
            this.dialogFeedback = false;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.deal_with_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                .btn1,
                .btn2 {
                    display: inline-block;
                    width: 96px;
                    height: 32px;
                    background: #f0f0f0;
                    box-shadow: 0px 2px 4px 0px rgba(252, 239, 198, 0.6);
                    border-radius: 2px;
                    text-align: center;
                    line-height: 32px;
                    font-weight: normal;
                    cursor: pointer;
                    img {
                        width: 16px;
                        height: 16px;
                        vertical-align: -3px;
                        margin-right: 3px;
                    }
                }
                .btn2.active {
                    background: #f7ece5;
                    margin: 0 8px 0 0;
                }
                .btn1.active {
                    background: #fcefc6;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 20px 0 12px;
            height: 26px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            background: #2370eb;
            i {
                margin-right: 6px;
                font-size: 12px;
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
.dialog_info {
    width: 85%;
    margin: 25px auto 0;
    padding-bottom: 45px;
    font-size: 14px;
    color: #333333;
    p {
        margin-bottom: 16px;
        font-weight: 600;
        text-align: center;
    }
    i {
        display: block;
        font-size: 60px;
        font-weight: normal;
        margin-bottom: 24px;
    }
    span {
        display: inline-block;
        width: 215px;
        height: 48px;
        background: #f9fafc;
        border-radius: 8px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        i {
            color: #2370eb;
        }
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    height: 50px;
    line-height: 45px;
    border-top: 1px solid #eeeeee;
    p {
        color: #8d9299;
        font-size: 12px;
    }
}
.item {
    margin-left: 12px;
    margin-top: -2px;
    margin-right: 12px;
}
/deep/ .el-badge__content.is-fixed {
    top: 5px;
    right: 12px;
}
</style>
