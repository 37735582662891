<template>
    <div class="detail_box">
        <p v-if="data.status == 3 || data.status == 2">
            <i class="el-icon-warning-outline"></i>
            工资条将在
            <span>{{ timeDate }}</span>
            后自动确认（如果对本工资条有异议请及时进行反馈）
        </p>
        <div class="basic_wages">基本工资</div>

        <div class="basic_wages_constitute">
            <p>
                <span>基本薪资</span>
                <span> {{ Number(data.bottomAmount).toFixed(2) || 0 }}</span>
            </p>
            <p>
                <span>岗位工资</span>
                <span>{{ Number(data.postAmount).toFixed(2) || 0 }}</span>
            </p>
            <p>
                <span>职级薪资</span>
                <span>{{ Number(data.rankAmount).toFixed(2) || 0 }}</span>
            </p>
            <p>
                <span>绩效薪资</span>
                <span>{{ Number(data.meritsAmount).toFixed(2) || 0 }}</span>
            </p>
            <p>
                <span>全勤</span>
                <span>{{ Number(data.fullAmount).toFixed(2) || 0 }}</span>
            </p>
            <p>
                <span>基本工资</span>
                <span>{{ Number(data.baseAmount).toFixed(2) || 0 }}</span>
            </p>
            <p>
                <span>小计</span>
                <span>{{ Number(data.baseTotalAmount).toFixed(2) || 0 }}</span>
            </p>
        </div>
        <div class="work_overtime">
            <div>
                <div>加班栏</div>
                <div>
                    <p>
                        <span>加班餐补</span>
                        <span>{{
                            Number(data.workAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>加班天数</span>
                        <span>{{ Number(data.workDay).toFixed(2) || 0 }}</span>
                    </p>
                    <p>
                        <span>小计</span>
                        <span>{{
                            Number(data.workTotalAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                </div>
            </div>
            <div>
                <div>提成</div>
                <div>
                    <p>
                        <span>个人提成</span>
                        <span>{{
                            Number(data.personalAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>管理提成</span>
                        <span>{{
                            Number(data.manageAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>专项奖金</span>
                        <span>{{
                            Number(data.specialAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>小计</span>
                        <span>{{
                            Number(data.commissionTotalAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                </div>
            </div>
            <div class="other">
                <div>其他</div>
                <div>{{ Number(data.otherTotalAmount).toFixed(2) || 0 }}</div>
            </div>
        </div>

        <div class="vacation">
            <div>
                <div>假扣</div>
                <div>
                    <p>
                        <span>病假(天)</span>
                        <span>{{
                            Number(data.sickAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>事假(天)</span>
                        <span>{{
                            Number(data.matterAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>旷工(天)</span>
                        <span>{{
                            Number(data.absenteeismAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>小计</span>
                        <span>{{
                            Number(data.leaveTotalAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                </div>
            </div>
            <div>
                <div>代扣代缴</div>
                <div>
                    <p>
                        <span>社保</span>
                        <span>{{
                            Number(data.socialAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>公积金</span>
                        <span>{{
                            Number(data.reserveAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>个税</span>
                        <span>{{
                            Number(data.taxAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                    <p>
                        <span>小计</span>
                        <span>{{
                            Number(data.taxTotalAmount).toFixed(2) || 0
                        }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="last_div">
            应发合计
            <span>{{ returnGrant().toFixed(2) }}</span>
        </div>
        <div class="last_div">
            代扣合计
            <span>{{ Number(data.taxTotalAmount).toFixed(2) || 0 }}</span>
        </div>
        <div class="last_div">
            实发工资
            <span>{{
                (returnGrant() - Number(data.taxTotalAmount)).toFixed(2)
            }}</span>
        </div>

        <div class="bottom_btn">
            <el-button
                round
                @click="close"
                size="mini"
                style="padding: 8px 25px"
                >取 消</el-button
            >
            <el-button
                type="primary"
                style="background-color: #2370eb; padding: 8px 25px"
                round
                size="mini"
                @click="feedback"
                v-if="data.status != 4"
                >反 馈</el-button
            >
            <el-button
                type="primary"
                style="
                    margin-right: 16px;
                    background-color: #2370eb;
                    padding: 8px 25px;
                "
                round
                v-if="data.prolong == 0 && data.status == 3"
                @click="extend"
                size="mini"
                >延长确认</el-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            data: {},
            timeDate: '',
            a: null,
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            clearInterval(this.a);
            this.data = data;
            var setTime = new Date(this.data.expireTime);
            var now = new Date().getTime();
            console.log(new Date(this.data.expireTime).getTime());
            if (setTime.getTime() < now) {
                this.timeDate = '';
                clearInterval(this.a);
            } else {
                this.time(setTime);
            }
        },
        closeTime() {
            this.data.status = 5;
            clearInterval(this.a);
            this.timeDate = '';
        },
        returnGrant() {
            return (
                Number(this.data.baseTotalAmount) +
                Number(this.data.workTotalAmount) +
                Number(this.data.commissionTotalAmount) +
                Number(this.data.otherTotalAmount) -
                Number(this.data.leaveTotalAmount)
            );
        },
        feedback() {
            this.$emit('feedback', this.data);
        },
        time(setTime) {
            this.timeDate = this.countDown(setTime);
            this.a = setInterval(() => {
                this.timeDate = this.countDown(setTime);
            }, 1000);
        },
        countDown(setTime) {
            var now = new Date().getTime();
            var times = (setTime.getTime() - now) / 1000;
            if (times < 0) {
                this.timeDate = '';
                clearInterval(this.a);
                this.close();
            }
            var h = parseInt(times / 60 / 60); //时
            var m = parseInt((times / 60) % 60); //分
            m = m < 10 ? '0' + m : m;

            var s = parseInt(times % 60); //秒
            s = s < 10 ? '0' + s : s;

            return h + '小时' + m + '分' + s + '秒';
        },
        extend() {
            this.$emit('extend', this.data);
        },
        close() {
            this.$emit('close');
        },
    },
    destroyed() {
        clearInterval(this.a);
    },
};
</script>

<style lang="scss" scoped>
.el-icon-warning-outline {
    color: #f5a623;
}
.detail_box {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 9px;
    > p {
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px dashed #f5a623;
        font-size: 14px;
        margin: 0px 12px 9px;
        span {
            color: #f5a623;
        }
    }
    .basic_wages {
        height: 48px;
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #eeeeee;
        text-align: center;
        line-height: 48px;
        font-weight: 600;
        font-size: 13px;
    }
    .basic_wages_constitute {
        display: flex;
        p {
            flex: 1;
            text-align: center;
            span {
                display: block;
                border-bottom: 1px solid #eeeeee;
                padding: 10px 0;
                font-size: 13px;
                color: #333;
            }
            span:first-child {
                color: #666;
            }
        }
    }
    .work_overtime {
        border-radius: 4px 4px 0px 0px;
        display: flex;
        margin-top: 16px;
        font-size: 13px;
        > div:first-child {
            width: 195px;
        }
        > div:nth-child(2) {
            flex: 1;
        }
        > div {
            text-align: center;
            box-sizing: border-box;

            border-right: 1px solid #e5e5e5;
            > div:first-child {
                line-height: 48px;
                height: 48px;
                background: #ebf3ff;
                font-weight: 600;
                border-bottom: 1px solid #eeeeee;
            }
            > div:last-child {
                display: flex;
                p {
                    flex: 1;
                    text-align: center;
                    span {
                        display: block;
                        border-bottom: 1px solid #eeeeee;
                        padding: 10px 0;
                        font-size: 13px;
                        color: #333;
                    }
                    span:first-child {
                        color: #666;
                    }
                }
            }
        }
        > div:last-child {
            width: 85px;
        }
        .other {
            > div:last-child {
                display: block;
                height: calc(100% - 50px);
                line-height: 76px;
                border-bottom: 1px solid #eee;
            }
        }
    }
    .vacation {
        display: flex;
        margin-top: 16px;
        margin-bottom: 18px;
        > div:first-child {
            border-right: 1px solid #eee;
        }
        > div {
            flex: 1;
            font-size: 14px;
            > div:first-child {
                height: 48px;
                background: #fff9f0;
                border-radius: 4px 4px 0px 0px;
                text-align: center;
                line-height: 48px;
                font-weight: 600;
                border-bottom: 1px solid #eeeeee;
            }
            > div:last-child {
                display: flex;
                p {
                    flex: 1;
                    text-align: center;
                    span {
                        display: block;
                        border-bottom: 1px solid #eeeeee;
                        padding: 10px 0;
                        font-size: 13px;
                        color: #333;
                    }
                    span:first-child {
                        color: #666;
                    }
                }
            }
        }
    }
    .last_div {
        margin: 0 12px;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 600;
        background: #f9fafc;
        border-radius: 8px;
        span {
            float: right;
        }
    }
    .last_div + .last_div {
        margin-top: 6px;
    }

    .bottom_btn {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
    }
}
</style>
