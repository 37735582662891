<template>
    <div class="chat_box">
        <div class="box" ref="box">
            <div class="chat" v-for="item in feedbackListData" :key="item.id">
                <p>{{ item.createTime }}</p>
                <div class="other_chat" v-if="adminId != item.adminId">
                    <img src="@/img/Cute.png" alt="" />
                    <p>
                        <i>{{ item.adminName }}</i>
                        <span>{{ item.content }}</span>
                    </p>
                </div>
                <div class="my_chat" v-else>
                    <p>
                        <i>{{ item.adminName }}</i>
                        <span>{{ item.content }}</span>
                    </p>
                    <img src="@/img/Cute_animal.png" alt="" />
                </div>
            </div>
            <p
                v-if="data.status == 4"
                style="
                    color: #2370eb;
                    text-align: center;
                    line-height: 3;
                    font-size: 12px;
                "
            >
                工资条已确认 ~
            </p>
        </div>
        <div class="form_box" v-if="data.status != 4">
            <el-form label-position="left" size="small" label-width="75px">
                <el-form-item label="反馈内容" required>
                    <el-input
                        v-model="content"
                        placeholder="请输入"
                        type="textarea"
                        rows="3"
                        style="width: 360px"
                    ></el-input>
                    <el-button
                        type="primary"
                        style="
                            background: #2370eb;
                            padding-left: 25px;
                            padding-right: 25px;
                        "
                        @click="wagesGet"
                        >发送</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import {
    feedbackList,
    feedbackAdd,
    wagesUpdate,
    wagesGet,
} from '@/api/wages/wages.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            content: '',
            data: {},
            feedbackListData: [],
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.data = data;
            this.content = '';
            this.feedbackList();
        },
        onSend() {},
        feedbackList() {
            let data = {
                param: {
                    wagesDetailId: this.data.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            feedbackList(data).then((res) => {
                if (res.code == 200) {
                    this.feedbackListData = res.data.list;
                    this.nextTick();
                }
            });
        },
        nextTick() {
            this.$nextTick(() => {
                this.$refs.box.scrollTop = this.$refs.box.scrollHeight;
            });
        },

        wagesGet() {
            let data = {
                param: {
                    id: this.data.id,
                },
            };
            wagesGet(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.status == 6) {
                        this.$emit('close');
                        return this.$message.error(
                            '当前工资条状态异常，无法完成操作'
                        );
                    }

                    let data = {
                        param: {
                            wagesId: this.data.wagesId,
                            wagesDetailId: this.data.id,
                            content: this.content,
                        },
                    };
                    if (!this.content) {
                        return this.$message.error('请输入反馈内容');
                    }
                    feedbackAdd(data).then((res2) => {
                        if (res2.code == 200) {
                            let data = {
                                param: {
                                    id: this.data.id,
                                    status: 5,
                                },
                            };
                            wagesUpdate(data).then((res2) => {
                                if (res2.code == 200) {
                                    res.data.status = 5;
                                    this.$emit('closeTime', res.data);
                                }
                            });
                            this.$message.success('操作成功');
                            this.content = '';
                            this.feedbackList();
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.chat_box {
    background-color: #f9f9f9;
    overflow: hidden;
    .box {
        height: 350px;
        overflow-y: auto;
        background-color: #fff;
        margin-bottom: 8px;
        padding-bottom: 16px;
        box-sizing: border-box;
    }
    .chat {
        padding: 16px 22px 0px;
        background-color: #fff;

        > p {
            text-align: center;
            font-size: 12px;
            color: #8d9299;
        }
        .my_chat {
            justify-content: flex-end;
            p {
                padding-right: 8px;
                padding-left: 0;
                i {
                    text-align: right;
                }
                span {
                    background-color: #cfe5fa;
                }
                span:before {
                    content: '';
                    position: absolute;
                    right: -10px;
                    left: auto;
                    top: 14px;
                    width: 0px;
                    height: 0px;
                    border: 5px solid #000;
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: #cfe5fa;
                    border-right-color: transparent;
                }
            }
        }
        > div {
            display: flex;
            align-content: center;
            width: 100%;
            img {
                width: 38px;
                height: 38px;
            }
            p {
                padding-left: 8px;
                i {
                    display: block;
                    margin-top: -5px;
                    margin-left: 3px;
                    font-style: normal;
                    font-size: 12px;
                    color: #818181;
                }
                span {
                    display: block;
                    padding: 9px 10px;
                    background-color: #f3f3f3;
                    margin-top: 2px;
                    position: relative;
                    font-size: 14px;
                    color: #333333;
                    border-radius: 4px;
                }
                span:before {
                    content: '';
                    position: absolute;
                    left: -10px;
                    top: 14px;
                    width: 0px;
                    height: 0px;
                    border: 5px solid #000;
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: #f3f3f3;
                }
            }
        }
    }
    .form_box {
        padding-left: 24px;
        background-color: #fff;
        padding-top: 7px;
        padding-bottom: 7px;
        position: relative;
        /deep/ .el-form-item {
            margin-bottom: 0 !important;
        }
        button {
            position: absolute;
            top: 0;
            right: 18px;
        }
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
</style>
